import { PanelPlugin } from '@grafana/data';
import { DCFabricPanel } from './DCFabricPanel';
import { DCFabricOptions } from './types';

export const plugin = new PanelPlugin<DCFabricOptions>(DCFabricPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'content',
      name: 'Html Beschreibung der Spritzgussmaschine',
      description: 'Formatierte Html Beschreibung der Spritzgussmaschine',
      defaultValue: '',
      settings: {
        useTextarea: true,
      },
    })
    .addTextInput({
      path: 'potentialQueryName',
      name: 'Potential(QueryName)',
      description: 'Name der Query',
      defaultValue: '',
    })
    .addTextInput({
      path: 'potentialQueryUnit',
      name: 'Potential (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '',
    });
});
