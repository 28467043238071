import React from 'react';
import { css, cx } from 'emotion';
import { stylesFactory, VerticalGroup, HorizontalGroup } from '@grafana/ui';

export interface KpiPanelOptions {
  header: string | undefined;
  value: string | undefined;
  state: 'good' | 'bad' | 'none' | undefined;
  deltaInPercent: string | undefined;
  unit: string;
}

export const KPIItem: React.FC<KpiPanelOptions> = (options: KpiPanelOptions) => {
  const styles = getStyles();

  return (
    <div
      className={cx(css`
        background: #303030;
        padding: 1rem};
      `)}
    >
      <VerticalGroup>
        <p className={styles.header}>{options.header}</p>
        <HorizontalGroup align="center">
          <h1
            className={cx(css`
              font-size: 2.5rem};
              font-weight: 600;
            `)}
          >
            {options.value ? options.value + options.unit : '...'}
          </h1>
          {options.deltaInPercent && (
            <HorizontalGroup align="center" justify="center">
              <svg
                className={cx(css`
                  margin-left: 5px;
                  height: 25px;
                  width: 25px;
                  top: -10px;
                  ${options.state === 'bad' && 'transform: rotate(180deg);'}
                `)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={options.state === 'good' ? '#8bb948' : options.state === 'bad' ? '#c3302a' : '#303030'}
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
              </svg>
              <p
                className={cx(
                  options.state === 'good'
                    ? styles['state-good']
                    : options.state === 'bad'
                    ? styles['state-bad']
                    : undefined
                )}
              >
                {options.deltaInPercent}%
              </p>
            </HorizontalGroup>
          )}
        </HorizontalGroup>
      </VerticalGroup>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    header: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    'state-good': css`
      color: #8bb948;
    `,
    'state-bad': css`
      color: #c3302a;
      }
    `,
  };
});
