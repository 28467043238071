import React from 'react';
import { PanelProps, PanelData, textUtil } from '@grafana/data';
import { stylesFactory, Button, Modal, VerticalGroup, HorizontalGroup, useTheme2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { debounce } from 'lodash';
import DangerouslySetHtmlContent from 'dangerously-set-html-content';

import { DCFabricOptions } from './types';
import { KPIItem, KpiPanelOptions } from './KPI-Item';

import { getValueFromQuery, calcChangeInPercentage } from 'uef-common';

const flowImage = require('./img/flow.jpg');
const fabricImage = require('./img/fabric.png');

interface Props extends PanelProps<DCFabricOptions> {}

export const DCFabricPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [html, setHtml] = React.useState('');
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [potential, setPotential] = React.useState<KpiPanelOptions | undefined>();

  const updateHTML = debounce(() => {
    const newHtml = processContent(options);
    if (newHtml !== html) {
      setHtml(newHtml);
    }
  }, 150);

  const prepareHTML = (html: string): string => {
    return interpolateAndSanitizeString(html);
  };

  const interpolateAndSanitizeString = (content: string): string => {
    return textUtil.sanitize(content);
  };

  const processContent = (options: DCFabricOptions): string => {
    const { content } = options;

    if (!content) {
      return '';
    }

    return prepareHTML(content);
  };

  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);

  React.useEffect(() => {
    // Since any change could be referenced in a template variable,
    // This needs to process every time (with debounce)
    updateHTML();
  }, [html, updateHTML]);

  React.useEffect(() => {
    setPotential((prev) => getKpiPanelOptions(data, options.potentialQueryName, prev, options.potentialQueryUnit));
  }, [data, options]);

  const theme = useTheme2();
  const styles = getStyles();
  const isLarge = width > theme.breakpoints.values.md;
  const ResponsiveContainer = isLarge ? HorizontalGroup : VerticalGroup;

  const getKpiPanelOptions = (
    data: PanelData,
    columnName: string | undefined,
    currentValue: KpiPanelOptions | undefined,
    unit: string
  ): KpiPanelOptions | undefined => {
    if (!columnName) {
      return undefined;
    }
    const value = getValueFromQuery(data, columnName);
    const deltaInPercent = calcChangeInPercentage(currentValue?.value, value);
    const kpiOptions: KpiPanelOptions = {
      header: columnName,
      value: value,
      deltaInPercent: deltaInPercent?.toFixed(1),
      state: deltaInPercent ? (deltaInPercent > 0 ? 'good' : 'bad') : 'none',
      unit: unit,
    };
    return kpiOptions;
  };

  const renderPotential = () => (
    <div
      className={cx(css`
        width: ${isLarge ? '270px' : '100%'};
        height: 180px;
      `)}
    >
      <KPIItem
        header={potential?.header}
        state={potential?.state}
        value={potential?.value}
        unit={potential?.unit ?? ''}
        deltaInPercent={potential?.deltaInPercent}
      />
    </div>
  );

  return (
    <VerticalGroup>
      <h1 className={cx(styles.title)}>Gleichstromfabrik | Spritzgussmaschine</h1>
      <ResponsiveContainer spacing="lg" justify="center" align="center">
        <img
          className={cx(css`
            max-height: ${isLarge ? '600px' : '300px'};
          `)}
          src={fabricImage}
        />
        <VerticalGroup>
          <DangerouslySetHtmlContent html={html} className={cx('markdown-html', styles.content)} />
          <Button size="lg" onClick={openModal} className={cx(styles.button)}>
            Messpunkte
          </Button>
        </VerticalGroup>
        <Modal title={'Messpunkte'} isOpen={modalIsOpen} onDismiss={closeModal}>
          <img src={flowImage} height="100%" width={'100%'} />
        </Modal>
      </ResponsiveContainer>
      {isLarge ? <HorizontalGroup justify="center">{renderPotential()}</HorizontalGroup> : renderPotential()}
    </VerticalGroup>
  );
};

const getStyles = stylesFactory(() => {
  return {
    title: css`
      margin-left: 20px;
      margin-right: 20px;
    `,
    content: css`
      height: 100%;
    `,
    button: css`
      margin-top: 20px;
      background: transparent;
      border: 2.1px solid #00baf1;
      color: #00baf1;
      &:hover {
        background: #00baf1;
        color: white;
      }
    `,
  };
});
